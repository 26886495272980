/* this file is transformed by vux-loader */
/* eslint-disable */
import { PinchZoom } from '@/assets/pinchzoom.js';
export default {
  name: 'flowImage',
  data() {
    return {
      "imgUrl": ""
    };
  },
  props: {
    task: {
      type: String,
      default: ''
    },
    type: String,
    id: String
  },
  created() {
    let id = this.$route.params.id;
    let type = this.$route.params.type;
    var url = this.getRealUrl() + "/bpm/activiti/processImage.do?";
    if (this.task) {
      url += "taskId=";
      url += this.task;
    } else {
      switch (type) {
        case "task":
          url += "taskId=";
          break;
        case "inst":
          url += "instId=";
          break;
        case "start":
          url += "actDefId=";
          break;
      }
      url += id;
    }
    console.log(url);
    this.imgUrl = url;

    //this.$dialog.loading.open('正在加载流程图,请稍候...');
  },

  mounted() {
    let el = document.getElementById('processImg');
    let pz = new PinchZoom(el);
    el.onload = () => {
      this.$dialog.loading.close();
    };
  }
};